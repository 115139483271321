h1 {
    font-size: 32px;
}

h2 {
    font-size: 26px;
}

h3 {
    font-size: 20px;
}

h4 {
    font-size: 16px;
}

&,
.sml-txt {
    font-size: 12px;
}

label,
.mat-tooltip {
    font-size: 14px;
}

@media (min-width: 600px) {
    h1 {
        font-size: 36px;
    }

    h2 {
        font-size: 28px;
    }

    h3 {
        font-size: 22px;
    }

    h4 {
        font-size: 18px;
    }

    &,
    .sml-txt {
        font-size: 14px;
    }

    label,
    .mat-tooltip {
        font-size: 16px;
    }
}

@media (min-width: 1285px) {
    h1 {
        font-size: 40px;
    }

    h2 {
        font-size: 32px;
    }

    h3 {
        font-size: 28px;
    }

    h4 {
        font-size: 22px;
    }

    &,
    .sml-txt {
        font-size: 16px;
    }

    label,
    .mat-tooltip {
        font-size: 20px;
    }
}
