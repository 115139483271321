// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;

@import "./app/scss/_index.scss";
@import "./app/scss/base.scss";
@import "./app/scss/fonts-material.scss";
@import "./app/scss/fonts-roboto.scss";

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$FUMapp-primary: mat.m2-define-palette($palette-primary, 500);
$FUMapp-accent: mat.m2-define-palette($secondary-primary, 400);

// The warn palette is optional (defaults tored).
$FUMapp-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$FUMapp-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $FUMapp-primary,
      accent: $FUMapp-accent,
      warn: $FUMapp-warn,
    ),
    typography: mat.m2-define-typography-config(),
    density: 0,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($FUMapp-theme);

body {
  margin: 0 auto;
  min-width: 350px;
  padding: 0;
  font-family: $main-font;
  background-color: darken($gray, 15%);

  @import "./app/scss/btns.scss";
  @import "./app/scss/font-size.scss";
  @import "./app/scss/inputs.scss";
  @import "./app/scss/form.scss";
  @import "./app/scss/popup.scss";
  @import "./app/scss/stepper.scss";
}
