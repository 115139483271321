.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  @include disable-selection();

  color: $white;
  background-color: $dark-gray;
  border: 1px solid darken($dark-gray, 8%);

  &:hover {
    background-color: darken($dark-gray, 5%);
    border-color: darken($dark-gray, 3%);
  }

  &:active,
  &.active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }

  &.disabled {
    pointer-events: none;
  }
}
.btn.sml-btn {
  font-size: 12px;
  padding: 4px 8px;
}
.btn.btn-one {
  color: $dark-gray;
  background-color: $white;
  border-color: darken($white, 15%);
  &:hover {
    background-color: darken($white, 5%);
    border-color: darken($white, 12%);
  }
}
.btn.btn-two {
  color: white;
  background-color: $purple-electric;
  border-color: darken($purple-electric, 5%);
  &:hover {
    background-color: darken($purple-electric, 5%);
    border-color: darken($purple-electric, 3%);
  }
  &:active,
  &.active {
    background: repeating-radial-gradient(lighten($purple-electric, 10%), darken($purple-electric, 5%) 100px);
  }
}
.btn.btn-three {
  color: white;
  background-color: $green-harlequin;
  border-color: darken($green-harlequin, 5%);
  &:hover {
    background-color: darken($green-harlequin, 5%);
    border-color: darken($green-harlequin, 3%);
  }
}
.btn.btn-four {
  color: white;
  background-color: $denim;
  border-color: darken($denim, 5%);
  &:hover {
    background-color: darken($denim, 5%);
    border-color: darken($denim, 3%);
  }
  &:active,
  &.active {
    background: repeating-radial-gradient(lighten($denim, 10%), darken($denim, 5%) 100px);
  }
}

.btn-wrapper {
  margin-top: 20px;
  .btn {
    margin-right: 10px;
  }

  &.lft-rht {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .lft .btn {
      margin: 0 10px 0 0;
    }
    .rht .btn {
      margin: 0 0 0 10px;
    }
  }
}
