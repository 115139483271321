div.popup {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9970;
    div.modal-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-content: flex-start;
        padding: 25px;
        height: 100%;
        overflow: auto;

        div.modal {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 15px;
            padding: 15px;
            min-width: 300px;
            background-color: white;
            border: 2px solid $razzmatazz;
            z-index: 9971;
            div.btn-wrapper {
                div.btn {
                    margin-right: 10px;
                }
            }
            form {
                min-width: 400px;
            }
        }
    }
    div.back-drop {
        width: 100%;
        height: 100%;
        background-color: gray;
        opacity: 0.5;
        pointer-events: none;
    }
}

@media (min-width: 600px) {
    div.popup {
        div.modal-wrapper {
            padding: 50px 30px;
            div.modal {
                padding: 25px;
                gap: 30px;
            }
        }
    }
}

@media (min-width: 600px) {
    div.popup {
        div.modal-wrapper {
            padding: 100px;
            div.modal div.content {
                max-width: 650px;
            }
        }
    }
}
