@mixin bottom-right-box-shadow-light() {
    -webkit-box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
}

@mixin bottom-right-box-shadow() {
    -webkit-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.3);
}

@mixin top-right-box-shadow() {
    -webkit-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 2px -1px 6px 2px rgb(149 149 149 / 30%);
}

@mixin active-inset-box-shadow() {
    -webkit-box-shadow: inset 0px 2px 20px 4px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: inset 0px 2px 20px 4px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0px 2px 20px 4px rgba(0, 0, 0, 0.3);
}

@mixin bg-linear-gradient($startColor, $endColor, $textColor) {
    background: linear-gradient(45deg, $startColor, $endColor);
    color: $textColor;
}

@mixin rotate-transition() {
    -webkit-transition: -webkit-transform 0.8s ease-in-out;
    -ms-transition: -ms-transform 0.8s ease-in-out;
    transition: transform 0.8s ease-in-out;
}
@mixin rotate-transform() {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

@mixin rotate-transform-half() {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

@mixin disable-selection() {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
