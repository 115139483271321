$header-nav-height: 65px;
$max-width: 1430px;
$ham-width: 1000px;
$neg-ham-width: -1001px;

// ---------------------------- colors
$white: #fff;
$ligther-gray: #c3c3c3;
$light-gray: #bebebe;
$gray: #7e7e7e;
$dark-gray: #3f3f3f;
$black: #000;

$blue-picton: #57a8dd;
$green-harlequin: #27ff00;
$dark-orange: #ff8c00;
$pink-cabaret: #c44e76;
$pink-pig-one: #fdd7e4;
$purple-heart: #592ce0;
$purple-electric: #a200ff;
$violet-dark: #7800bd;
$gray-purp: #b37fc3;
$black-currant: #260040;
$denim: #0f50cf;
$razzmatazz: #e91e63;
$red: red;

// ---------------------------- fonts
$main-font-color: $dark-gray;
$main-font: "Graphik Regular", "Arial", Sans-Serif;
$sub-font-color: darken($dark-gray, 5%);
$sub-font: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, sans-serif;

$palette-primary: (
    50: #efe5fd,
    100: #e5c0ff,
    200: #d495ff,
    300: #c167ff,
    400: #b13fff,
    500: #a200ff,
    600: #8d0bf9,
    700: #6e11f2,
    800: #4e13ed,
    900: #0016e9,
    contrast: (
        50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: rgba(black, 0.87),
        400: rgba(black, 0.87),
        500: rgba(white, 0.87),
        600: rgba(white, 0.87),
        700: rgba(white, 0.87),
        800: rgba(white, 0.87),
        900: rgba(white, 0.87),
    ),
);

$secondary-primary: (
    50: #ebffe5,
    100: #cdffbe,
    200: #a6ff91,
    300: #72ff58,
    400: #26ff00,
    500: #00fc00,
    600: #00ea00,
    700: #00d400,
    800: #00bf00,
    900: #009900,
    contrast: (
        50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: rgba(black, 0.87),
        400: rgba(black, 0.87),
        500: rgba(black, 0.87),
        600: rgba(black, 0.87),
        700: rgba(white, 0.87),
        800: rgba(white, 0.87),
        900: rgba(white, 0.87),
    ),
);
