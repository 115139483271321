section.bordered {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid $light-gray;

    label {
        font-size: 14px;
    }
}

section.columned {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 15px;
}

.stepper {

    div.col-lft,
    div.col-mdl,
    div.col-rht {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 15px;
    }
}

.mat-horizontal-content-container {
    padding: 0;
}

.mat-horizontal-stepper-header {
    padding: 0;
    pointer-events: none;
}


@media (min-width: 800px) {
    .stepper {
        display: flex;
        flex-flow: row wrap;
        gap: 10px 10px;
        align-content: center;
        justify-content: flex-start;
        align-items: stretch;

        div.col-lft,
        div.col-mdl,
        div.col-rht {
            flex: 1 1;
            overflow: auto;
        }

        div.col-wth {
            flex-basis: 100%;
        }

        div.col-flt {
            width: 500px;
        }

        &.sml-font,
        section label {
            font-size: 14px;
        }

        .risk-score-multiplier-field sup {
            margin-top: -2px;
        }
    }

    section section div.form-field {
        margin-top: 10px;
    }
}

@media (min-width: 1000px) {
    .stepper {
        gap: 30px 30px;

        div.col-lft,
        div.col-mdl,
        div.col-rht {
            gap: 15px;
        }

        section {
            margin-bottom: 30px;
        }
    }
}
