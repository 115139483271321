textarea.mat-mdc-input-element {
    font-family: $main-font;
    resize: none;
}

.mat-mdc-select,
.mat-mdc-input-element {
    padding: 8px;
    font-size: 12px;
    line-height: 1.42857143;
    color: $sub-font-color;
    background-color: $white;
    background-image: none;
    border: 2px solid lighten($light-gray, 2%);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    &::placeholder {
        color: lighten($sub-font-color, 5%);
    }
}

.mat-mdc-option {
    font-size: 12px;
}

.mat-mdc-input-element:focus,
.mat-mdc-select:focus {
    outline: 0;
    border-color: lighten($purple-electric, 5%);
    border-radius: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.mat-mdc-input-element:disabled,
.multi-select:disabled {
    opacity: 0.7;
}

// mat-select
.left-ellipsis {
    .mat-mdc-select-value,
    .mat-mdc-select-value-text,
    .mat-option-text {
        direction: rtl;
        text-align: left;
    }
}

@media (min-width: 800px) {
    .mat-mdc-select,
    .mat-mdc-input-element {
        font-size: 14px;
    }

    .mat-mdc-input-element {
        padding: 10px 14px;
    }

    .mat-mdc-select {
        padding: 8px 14px;
    }

    .mat-mdc-option {
        font-size: 14px;
    }

    .mat-paginator {
        .mat-mdc-select {
            padding: 10px 10px;
        }
    }

    select {
        padding: 11px 14px;
        font-size: 14px;
    }
}
