form {
    width: 100%;

    div.form-field {
      div.label-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 2px;
        label {
          display: flex;
        }
      }

      div.label-wrapper.lft-rht {
        flex-wrap: nowrap;
        justify-content: space-between;
        div.lft, div.rht {
          display: flex;
        }
      }

      label {
        color: darken($main-font-color, 2%);
        font-weight: 600;
        sup {
          margin-left: 4px;
          color: $red;
          font-size: 12px;
        }
      }

      div.input-wrapper {
        display: flex;
        .mat-mdc-input-element,
        .mat-mdc-select {
          width: 100%;

          &:disabled {
            cursor: text;
          }
        }
      }

      .mat-mdc-input-element.ng-pristine[required],
      .mat-mdc-input-element.ng-pristine.required,
      .mat-mdc-select-required.ng-pristine {
        border-left: 5px solid $purple-electric;
      }

      .mat-mdc-input-element.ng-pristine[disabled],
      .mat-mdc-input-element.ng-pristine.disabled,
      .mat-mdc-select-disabled.ng-pristine {
        border-left: 2px solid lighten($light-gray, 4%);
      }

      .mat-mdc-input-element.ng-touched.ng-invalid,
      .mat-mdc-select.ng-touched.ng-invalid,
      textarea.ng-touched.ng-invalid {
        border: 2px solid $razzmatazz;
      }
    }

    div.form-err-field {
      margin-top: 6px;
      color: $razzmatazz;
    }
    div.form-disable-dropdown-field {
      margin-top: 6px;
      color: $black;
      font-style: italic;
    }

    div.cross-validation-error-msg {
      margin-bottom: 20px;
      padding: 20px;
      color: darken($razzmatazz, 4%);
      background: lighten($razzmatazz, 45%);
      border: 1px solid darken($razzmatazz, 4%);
    }
  }

  @media (min-width: 800px) {
    form {
      div.form-field {
        div.label-wrapper {
          app-info-tooltip {
            margin-left: 4px;
          }
        }
      }
      div.form-err-field {
        margin-top: 10px;
      }
    }
  }
