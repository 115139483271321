h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 100;
    margin: 0;
}

p {
    margin: 0;
}

a {
    text-decoration: none;
    color: inherit;

    .link {
        font-family: $sub-font;
        font-style: italic;
        font-variant: normal;
        font-weight: 700;
    }
}

* {
    box-sizing: border-box;
}

.cb {
    clear: both;
}

.cb-after:after {
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
}

.chip {
    display: inline-block;
    border-width: 2px 2px;
    border-color: $purple-electric;
    border-style: solid;
    border-radius: 25px;
    line-height: 25px;
    margin-right: 5px;
    padding: 0px 10px;
}

ul.clean {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

button:disabled,
.disabled {
    cursor: not-allowed !important;
    pointer-events: none;
    opacity: 0.5;
}

.bold {
    font-weight: bold;
}

.sbld {
    font-weight: 500;
}

.sbld-italic {
    font-weight: 500;
    font-style: italic;
}

.txt-link {
    color: $green-harlequin;
}

.warning-txt {
    color: $razzmatazz;
}

.tt-truncate {
    max-width: unset !important;
    word-break: break-all;
    white-space: normal;
    &.in {
        opacity: 1 !important;
    }
}

@-webkit-keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.rotating {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
}
